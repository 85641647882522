import {mapActions, mapGetters, mapMutations} from "vuex";
import uniqBy from 'lodash/uniqBy';

//sections
import deliveryPayment from "@/modules/product-card/pages/all-about/section/attributes/delivery-payment/index.vue";
import Vue from "vue";

export default {
    name: "attributes",
    components: {
        deliveryPayment
    },
    data() {
        return {
            selectedColor: '',
            selected_any_color: false,
            selectedDimensions: '',
            selectedThickness: '',
            amount: 1,
            filters: [],
        }
    },
    watch: {
        '$route.params.slug'() {
            this.selectedColor = ''
        },
        selected_any_color() {
            this.selectedColor = ''
            if (this.selected_any_color) {
                let detectColor = Array.from(this.$refs.detectColor)
                detectColor.forEach(el => {
                    el.classList.remove('checked-border')
                })
            }

        }
    },
    mounted() {

        this.getBasket().then(() => {
            // this.updateBasket(this.secondBasket.basket).then(() => {
            // if (this.secondBasket.basket.products.length) {
            //   let product = this.secondBasket.basket.products.find(el => {
            //     return el.id === this.currentProduct.id
            //   })
            //   return this.amount = product.quantity
            // }
            // })
        })
        this.filters = []
    },
    created() {


    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct',
            productCount: 'basket/basketCount',
            secondBasket: 'basket/secondBasket'
        }),
        productStatusColor() {
            if (this.currentProduct.productStatus.key === 'in_stock') {
                return '#5ED84B'
            }
            if (this.currentProduct.productStatus.key === 'not_available') {
                return '#0e3ab6'
            }
            if (this.currentProduct.productStatus.key === 'order') {
                return '#171616'
            }
            if (this.currentProduct.productStatus.key === 'waiting') {
                return '#0e3ab6'
            }
        },
    },

    methods: {
        ...mapMutations({
            addToBasket: `basket/ADD_ITEM_TO_BASKET`,
            increment: 'basket/INCREMENT_BASKET_LIST_COUNT',
            decrement: 'basket/DECREMENT_BASKET_LIST_COUNT',
            setCount: 'basket/CHANGE_BASKET_COUNT',
            setProductCount: 'basket/SET_PRODUCT_COUNT',
            showFastOrderPopup: 'order/FAST_ORDER_POPUP',
            setProduct: 'order/SET_PRODUCT_FOR_FAST_ORDER',
            isShowOrderColor: 'order/SHOW_ORDER_COLOR_POPUP'

        }),
        ...mapActions({
            fetchAttributes: 'card/GET_PRODUCT_WITH_ATTRIBUTE',
            basketProductAction: 'basket/BASKET_PRODUCT_ACTION',
            updateBasket: 'basket/UPDATE_BASKET',
            getBasket: 'basket/GET_BASKET'
        }),
        isEmptyCount() {
            return this.amount ? this.decrement : this.amount = 1
        },
        findSelectedAttributes(attr) {
            return this.currentProduct.attributes.some(el => {
                let attribute = el.value && el.value.title === attr
                return attribute
            })
        },
        getCurrentAttribute() {
            if (this.currentProduct) {
                this.currentProduct.attributes.forEach(attr => {

                    this.currentProduct.cardAttributes.attributes.forEach(el => {

                        el.values.forEach(val => {
                            if (attr.value && attr.value.title === val.title) {
                                this.filters.push({id: el.id, value: val.id})
                            }
                        })
                    })
                })
            }
        },

        getAttribute(id, value) {
            this.getCurrentAttribute()
            if (this.filters.length === 0) {
                this.filters.push({id: id, value: value})
            }
            this.filters.forEach((attr, idx) => {
                if (attr.id === id && attr.value !== value) {
                    attr.value = value
                }
                if (this.filters.length >= 1 && attr.id !== id && attr.value !== value) {
                    this.filters.push({id: id, value: value})
                }
            })
            let uniqFilters = uniqBy(this.filters, 'value', 'id')

            let arr = []
            uniqFilters.forEach(el => {
                let string = `attributes[${el.id}]=${el.value}`
                arr.push(string)
            })
            let payload = {}
            payload.slug = this.$route.params.slug
            payload.string = arr.join('&') + `&last_selected[${id}]=${value}`

            return this.fetchAttributes(payload)

        },
        goToProduct(slug) {
            this.$router.push({name: 'product', params: {slug: slug}}).then(() => {
                window.location.reload()
            }).catch(() => {
                console.log()
            })
        },
        increment() {
            this.amount++
        },
        decrement() {
            if (this.amount > 1) {
                this.amount--
            }
        },
        fastOrder() {
            let obj = {}

            obj.products = this.currentProduct
            obj.amount = this.amount
            obj.selected_any_color = this.selected_any_color

            this.setProduct(obj)
            this.showFastOrderPopup(true)
        },
        addBasket() {
            let obj = {}
            obj.id = this.currentProduct.id
            obj.quantity = this.amount
            obj.select_type = this.currentProduct
            obj.select_type.amount = this.amount
            obj.selected_any_color = this.selected_any_color
            // this.setCount(obj)
            this.basketProductAction({count: this.amount, product_id: this.currentProduct.id}).then(() => {
                // this.getBasket().then(() => {
                //   this.updateBasket(this.secondBasket.basket)
                // })
                return this.amount = 1

            })
            this.$toasted.success(this.$t('basket.successAddBasket'));
        },
        changeCount(type) {
            if (type === 'increment') {
                this.increment(this.currentProduct)
            } else if (type === 'decrement') {
                this.decrement(this.currentProduct)
            } else {
                this.setCount({
                    ...this.currentProduct,
                    quantity: parseInt(this.amount)
                })
            }
        },
    }
}
