import {mapActions, mapGetters, mapMutations} from "vuex";

//sections
import slider from "@/modules/product-card/pages/all-about/section/production/slider/index.vue";
import equipmentCard from "@/modules/product-card/pages/all-about/section/attributes/equipment-card/index.vue";
import deliveryPayment from "@/modules/product-card/pages/all-about/section/attributes/delivery-payment/index.vue";
import description from '@/modules/product-card/pages/all-about/section/attributes/index.vue'

export default {
    name: "production",
    components: {
        slider,
        equipmentCard,
        deliveryPayment,
        description
    },
    data() {
        return {
            buyTogether: [],
            images: [],
            amount: ''
        }
    },
    watch: {
        'currentProductLoading': function () {
            this.addMainImg()
        },
    },
    created() {
        this.addMainImg()
    },
    mounted() {
        this.addMainImg()
    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct',
            currentProductLoading: 'catalog/currentProductLoading',
            currentAmount: 'card/productCount',
            productCount: 'card/productCount',
            productAmount: 'basket/productCount',
            productLoading: 'card/productLoading'

        })
    },

    methods: {
        ...mapMutations({
            addToBasket: `basket/ADD_ITEM_TO_BASKET`,
            setCount: 'basket/CHANGE_BASKET_COUNT',
            saveTogetherProducts: 'basket/ADD_TOGETHER_PRODUCTS'
        }),
        ...mapActions({
            upd: 'basket/UPDATE_BASKET_CURRENT'
        }),
        addBasket() {
            let obj = {}
            obj.id = this.currentProduct.id
            obj.quantity = this.productAmount
            obj.select_type = this.currentProduct
            obj.select_type.amount = this.productAmount
            // this.setCount(obj)
            this.saveTogetherProducts(obj)

            this.buyTogether.forEach(el => {

                let obj = {}
                obj.id = el.id
                obj.select_type = el
                obj.select_type.amount = el.amount
                obj.quantity = el.amount
                this.saveTogetherProducts(obj)
            })
            this.$toasted.success(this.$t('basket.successAddBasket'));

            this.upd()
        },
        addMainImg() {
            this.images = []

            if (this.currentProduct.images && this.currentProduct.images.data.length) {
                this.images = []
                this.images = this.currentProduct.images.data
                let duplicate = this.images.find(el => el.path === this.currentProduct.image)
                if (!duplicate) {
                    this.images.unshift({type: 'image', path: this.currentProduct.image, title: this.currentProduct.title})
                }
            } else {
                this.images = []
                return this.images.push({type: 'image', path: this.currentProduct.image, title: this.currentProduct.title})
            }
            this.buyTogether = []
            if (this.currentProduct.buyTogether && this.currentProduct.buyTogether.data.length) {
                this.currentProduct.buyTogether.data.forEach(el => {
                    el.amount = 1
                    this.buyTogether.push(el)
                })
            }
            console.log(this.images, 'images');
        },
        deleteProduct(index) {
            this.buyTogether.splice(index, 1)
        },
        incrementProduct(data) {
            this.amount = data
        },
        decrementProduct(data) {
            this.amount = data
        },
        getIndex(index) {
            this.buyTogether[index].amount = this.amount
        }
    }
}
